import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { dayFormat, monthFormat } from '../../helper/dateFormat'
import { getScaledImage } from '../../helper/utilities'

const myQuery = graphql`
  {
    allStrapiBlog(limit: 3, sort: { fields: publishedOn, order: DESC }) {
      nodes {
        id
        blogImg {
          url
        }
        title
        shortDesc
        blogDetails
        created_at
        publishedOn
        slug
        authors {
          name
          slug
          id
        }
        tags {
          slug
          title
          id
        }
      }
    }
  }
`

const BlogArea = () => {
  const {
    allStrapiBlog: { nodes: blogs },
  } = useStaticQuery(myQuery)

  return (
    <div className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title text-center">
          <span className="sp-color1">Latest Blog</span>
          <h2>Let’s Check What our Team is Writing</h2>
        </div>

        <div className="row pt-45 justify-content-center">
          {blogs.map(blogcard => {
            const { authors, tags } = blogcard
            return (
              <div className="col-lg-4 col-md-6" key={blogcard.id}>
                <div className="blog-card">
                  <div className="blog-img">
                    <Link to={`/blogs/${blogcard.slug}`}>
                      <img
                        src={getScaledImage(blogcard.blogImg.url, 300)}
                        alt="Blog"
                        height="250px"
                      />
                    </Link>
                    <div className="blog-tag">
                      <h3>{dayFormat(blogcard.publishedOn)}</h3>
                      <span>{monthFormat(blogcard.publishedOn)}</span>
                    </div>
                  </div>
                  <div className="content">
                    <ul>
                      {authors &&
                        authors.map(author => {
                          return (
                            <li key={author.id}>
                              <Link to={`/blogs/${blogcard.slug}`}>
                                <i className="bx bxs-user" />
                                <span> {author.name} </span>
                              </Link>
                            </li>
                          )
                        })}
                      {tags &&
                        tags.map(tag => {
                          return (
                            <li key={tag.id}>
                              <Link to={`/blogs/${blogcard.slug}`}>
                                <i className="bx bx-purchase-tag-alt" />
                                <span> {tag.title} </span>
                              </Link>
                            </li>
                          )
                        })}
                    </ul>
                    <h3>
                      <Link to={`/blogs/${blogcard.slug}`}>{blogcard.title}</Link>
                    </h3>
                    <p>{blogcard.shortDesc}</p>
                    <Link to={`/blogs/${blogcard.slug}`} className="read-btn">
                      Read More <i className="bx bx-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogArea
