import React from 'react'

import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import BrandAreaTwo from '../components/Common/BrandAreaTwo'
import Footer from '../components/_App/Footer'
import BlogArea from '../components/Common/BlogArea'
import BrandArea from '../components/Common/BrandArea'
import CareerJobs from '../components/Career/CareerJobs'

const careers = () => {
  return (
    <Layout>
      <Seo title='Careers' />

      <TopHeader />

      <Navbar />

      <CareerJobs />

      <BrandAreaTwo />

      <BlogArea />

      <BrandArea />

      <Footer />
    </Layout>
  )
}

export default careers
