import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const myQuery = graphql`
  {
    allStrapiBrandTwo {
      nodes {
        id
        brandImg {
          url
        }
      }
    }
  }
`

const options = {
  loop: true,
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1024: {
      items: 5,
    },
  },
}

const BrandAreaTwo = () => {
  const {
    allStrapiBrandTwo: { nodes },
  } = useStaticQuery(myQuery)

  return (
    <div className="brand-area-two ptb-100">
      <div className="container">
        <div className="section-title text-center mb-40">
          <span className="sp-color2">Few out of many Technologies we use</span>
          <h2>Our Sample Technology Stack</h2>
        </div>

        <OwlCarousel className="brand-slider owl-theme" {...options}>
          {nodes.map(brandslider => {
            return (
              <div className="brand-item" key={brandslider.id}>
                <img src={brandslider.brandImg.url} alt="Brand Logo" height="80px" />
              </div>
            )
          })}
        </OwlCarousel>
      </div>
    </div>
  )
}

export default BrandAreaTwo
