import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const myQuery = graphql`
  {
    strapiCareersHeader {
      headerText
      helperText
      shortDesc
    }
    allStrapiCareers {
      nodes {
        id
        jobDetails
        jobId
        job_type {
          Type
        }
        location {
          city
        }
        department {
          name
        }
        shortDesc
        slug
        strapiId
        title
      }
    }
  }
`

const CareerJobs = () => {
  const {
    strapiCareersHeader: { helperText, headerText, shortDesc },
    allStrapiCareers: { nodes: jobs },
  } = useStaticQuery(myQuery)

  return (
    <section className="career-area pt-100 pb-70">
      <div className="container">
        <div className="section-title text-center">
          <span className="sp-color2">{helperText}</span>
          <h2>{headerText}</h2>
          <p className="margin-auto">
            Get to know us and join our team. We'd love to have more talented people on board. Come
            join us and help shape the future. For any queries, feel free to reach out to us at{' '}
            <a href="mailto:career@innoage.in?subject=Job Inquiry">career@innoage.in</a> or call us
            at <a href="tel:+919205551598">+91-9205551598</a>
          </p>
        </div>
        <div className="row">
          {jobs.map((job, index) => {
            return (
              <div className="career-item" key={index}>
                <Link to={`/careers/${job.slug}`}>
                  <div className="content">
                    <span className="job-title">{job.title}</span> &nbsp;
                    <span className="job-short-desc">({job.shortDesc})</span>
                    <ul>
                      <li>
                        <div className="disc">
                          <i className="bx bx-map-pin" />
                          <span>{job.location.city}</span>
                        </div>
                      </li>
                      <li>
                        <div className="disc">
                          <i className="bx bx-alarm" />
                          <span>{job.job_type.Type}</span>
                        </div>
                      </li>
                      <li>
                        <div className="disc">
                          <i className="bx bx-bookmark" />
                          <span>{job.department.name}</span>
                        </div>
                      </li>
                    </ul>
                    <Link to={`/careers/${job.slug}`} className="more-btn">
                      <i className="bx bx-right-arrow-alt" />
                    </Link>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CareerJobs
